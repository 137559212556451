import React from 'react'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation, { MenuButton } from '../../components/Infos/InfosNavigation'
import { StaticImage } from 'gatsby-plugin-image'

const RueFestive = () => {
  const metaTitle = 'Les arts de la rue Télé-Québec | Le Festif! de Baie-Saint-Paul'
  const metaDescription = ''
  const metaKeywords = 'rue, festive, festival, cirque, amuseur, festif, divertissement, arts, rue'

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader
        title="Les arts de la rue Télé-Québec"
      />
      <div className="container content pb-12">
        <div className="">
        <h1 className='lg:!text-[60px] !text-[50px] pb-6'>19 JUILLET DE 12h À 18h</h1>

          {/* TOP VIDEO */}
          <div className="max-w-[1920px] justify-center mx-auto">
            <center>
              <div className="text-center w-full">
                <video muted autoPlay loop playsInline className="rounded-3xl">
                  <source src="https://le-festif-ca.cdn.prismic.io/le-festif-ca/Zjdgt0MTzAJOCiiO_rue-festive.mp4" type="video/mp4" />
                </video>
              </div>
            </center>
          </div>

          {/* HIGHLIGHTED TEXT */}
          <div className='my-12 text-center'>
            <h5>Les arts de la rue Télé-Québec rendent hommage à la Fête Foraine de Baie-Saint-Paul, qui a vu le jour dans les années 80 et qui s’est ensuite métamorphosée pour devenir l’emblématique Cirque du Soleil. Acrobates, jongleur·euses, fanfares et musicien·nes ambulant·es prennent d’assaut la rue Saint-Jean-Baptiste, en plein cœur du Festif!. Profite de la dizaine d’artistes et de troupes venu·es des quatre coins de la planète en famille ou entre ami·es! On te donne rendez-vous dans cette ambiance déjantée et familiale!</h5>
          </div>

        </div>

        {/* MENU BUTTON */}
        <div className='list-none flex justify-center items-center flex-wrap max-w-[1200px] mx-auto mt-6'>
          <MenuButton color="bg-blue-wolf2025"/>
        </div>

        {/* HORAIRE */}
        <div className="flex flex-col md:flex-row gap-8 my-9">
          <div className="md:w-1/2 text-center">
            <h4 className="pb-2">RUE DE LA TANNERIE</h4>
            <a className='!no-underline hover:!underline' href="/artistes/fais-attention-a-ces-deux-la"><p className="pb-1"><b>12H00</b> - Attention à ces deux-là</p></a>
            <a className='!no-underline hover:!underline' href="/artistes/burning-brass-band"><p className="pb-1"><b>13H30</b> - Burning BRASs Band</p></a>
            <a className='!no-underline hover:!underline' href="/artistes/musical-puppets"><p className="pb-1"><b>14H45</b> - Musical Puppets</p></a>
            <a className='!no-underline hover:!underline' href="/artistes/burning-brass-band"><p className="pb-1"><b>16H15</b> - Burning BRASs Band</p></a>
            <a className='!no-underline hover:!underline' href="/artistes/fais-attention-a-ces-deux-la"><p className="pb-1"><b>17H30</b> - Attention à ces deux-là</p></a>
          </div>
          <div className="md:w-1/2 text-center">
            <h4 className="pb-2">RUE JEAN-TALON</h4>
            <a className='!no-underline hover:!underline' href="/artistes/hoopelai"><p className="pb-1"><b>12H00</b> - Hoopelaï</p></a>
            <a className='!no-underline hover:!underline' href="/artistes/hoopelai"><p className="pb-1"><b>13H45</b> - Hoopelaï</p></a>
            <a className='!no-underline hover:!underline' href="/artistes/flip-fabrique-les-400-coups"><p className="pb-1"><b>14H45</b> - FLIP Fabrique</p></a>
            <a className='!no-underline hover:!underline' href="/artistes/m-simon-et-ses-saltimbanques"><p className="pb-1"><b>15H30</b> - M. Simon et ses saltimbanques</p></a>
            <a className='!no-underline hover:!underline' href="/artistes/hoopelai"><p className="pb-1"><b>17H30</b> - Hoopelaï</p></a>
          </div>
        </div>

        {/* SPONSORS */}
        {/* <div className="content pt-12">
          <h2 className="!ml-0 !pl-0">Merci à nos partenaires</h2>
          <div className="max-w-[175px] py-4 ml-4">
            <a href="https://www.telequebec.tv/">
              <StaticImage src="../../images/partenaires/telequebec.png" alt="Logo Télé-Québec" imgClassName=""/>
            </a>
          </div>
        </div> */}

        {/* SPONSOR BANNER */}
        <div className="w-full center pt-8">
          <div className="max-w-[600px] max-h-[235px] mx-auto">
            <a className="" href="https://video.telequebec.tv/" rel="noreferrer noopener">
              <StaticImage src="../../images/partenaires/telquebec-banner.jpg"/>
            </a>
          </div>
        </div>
      </div>

    </Wolflayout>
  )
}

export default RueFestive
